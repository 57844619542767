// src/Components/UnauthorizedModal.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUnauthorized } from "../../../Store/slices/UserSlice";

const UnauthorizedModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unauthorized = useSelector((state) => state.user.unauthorized);
  const handleOkClick = () => {
    dispatch(setUnauthorized(false)); // Hide modal
    localStorage.clear(); // Clear local storage
    navigate("/"); // Redirect to login page
  };

  return unauthorized ? (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Session Expired</h3>
        <p className="py-4">Your session has expired. Please log in again.</p>
        <div className="modal-action">
          <button
            type="button"
            onClick={handleOkClick}
            className="btn btn-primary"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default UnauthorizedModal;
