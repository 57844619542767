import { authAxiosInstance } from "./axiosInterceptors";

// Function to handle login API call
export const loginApi = async (payload) => {
  try {
    const response = await authAxiosInstance.post("/auth/login", payload); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

// Function to handle signup API call
export const signUpApi = async (payload) => {
  try {
    const response = await authAxiosInstance.post("/auth/register", payload); // Assuming the signup endpoint is '/signup'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during signup:", error);
    throw error;
  }
};

// Function to update account settings
export const updateAccountSettingsApi = async (payload) => {
  try {
    const response = await authAxiosInstance.put("/profile", payload); // Assuming the API uses a PUT request
    return response; // Return the response data
  } catch (error) {
    console.error("Error updating account settings:", error);
    throw error;
  }
};

export const getUsersApi = async ({ page, rowsPerPage }) => {
  try {
    const response = await authAxiosInstance.get(
      `admin/users?pageSize=${rowsPerPage}&sortOrder=asc&sortBy=createdAt&page=${page}`
    );

    return response.data;
  } catch (error) {
    console.error("Error adding new user:", error);
    throw error;
  }
};

export const addNewUserApi = async (payload) => {
  try {
    const response = await authAxiosInstance.post(
      "/admin/create-user",
      payload
    );
    return response;
  } catch (error) {
    console.error("Error adding new user:", error);
    throw error;
  }
};

export const editUserApi = async (payload) => {
  try {
    const response = await authAxiosInstance.put(
      `/admin/user?id=${payload.id}`,
      payload
    ); // Adjust the endpoint as per your API
    return response; // Return the response data
  } catch (error) {
    console.error("Error editing user:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const toggleUserRoleApi = async (payload) => {
  try {
    const response = await authAxiosInstance.post(
      `/admin/assign-role?id=${payload.id}`,
      payload
    ); // Adjust the endpoint as per your API
    return response;
  } catch (error) {
    console.error("Error toggling user role:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const deleteUserApi = async (userId) => {
  try {
    const response = await authAxiosInstance.delete(`/admin/user?id=${userId}`);
    return response;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error.response
      ? error.response.data
      : new Error("Failed to delete user");
  }
};

export const updateThemeApi = async (theme) => {
  try {
    const response = await authAxiosInstance.put(
      `/profile/theme/?theme=${theme}`
    ); // Adjust the endpoint as per your API
    return response.data;
  } catch (error) {
    console.error("Error Updating user theme:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};
