/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem("user-token");

  return isAuthenticated ? <Element {...rest} /> : <Navigate to="/" />;
};
PrivateRoute.propTypes = {
  element: PropTypes.elementType.isRequired
};
export default PrivateRoute;
/* eslint-disable react/jsx-props-no-spreading */
