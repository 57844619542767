/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ element: Element, restricted, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem("user-token");

  return isAuthenticated && restricted ? (
    <Navigate to="/dashboard" />
  ) : (
    <Element {...rest} />
  );
};

PublicRoute.propTypes = {
  element: PropTypes.elementType.isRequired, // or PropTypes.node if you expect any React element
  restricted: PropTypes.bool.isRequired
};

export default PublicRoute;
/* eslint-disable react/jsx-props-no-spreading */
