import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

// Lazy load the page components
const Dashboard = lazy(() => import("../Pages/Dashboard"));
const Home = lazy(() => import("../Pages/Home"));
const LoginPage = lazy(() => import("../Pages/LoginPage"));
const SignupPage = lazy(() => import("../Pages/SignupPage"));
const Admin = lazy(() => import("../Pages/Admin"));
const ProfileSettings = lazy(() => import("../Pages/ProfileSettings"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute element={Dashboard} />}
        />
        <Route
          exact
          path="/saved-flow/:flowId"
          element={<PrivateRoute element={Home} />}
        />

        <Route
          exact
          path="/flow/:flowId"
          element={<PrivateRoute element={Home} />}
        />
        <Route
          exact
          path="/"
          element={<PublicRoute restricted element={LoginPage} />}
        />
        <Route
          exact
          path="/signup"
          element={<PublicRoute restricted element={SignupPage} />}
        />
        <Route
          exact
          path="/account-settings"
          element={<PrivateRoute element={ProfileSettings} />}
        />
        <Route exact path="/admin" element={<PrivateRoute element={Admin} />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
