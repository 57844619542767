import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateThemeApi } from "../../Services/authServices";

export const updateThemeThunk = createAsyncThunk(
  "theme/updateTheme",
  async (theme) => {
    const response = await updateThemeApi(theme);
    return response;
  }
);

const initialState = {
  mode: "light"
};

const ThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    updateTheme: (state, action) => {
      return {
        ...state,
        mode: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateThemeThunk.fulfilled, (state, action) => {
      return {
        ...state,
        mode: action.payload.theme.toLowerCase()
      };
    });
  }
});

export const { updateTheme } = ThemeSlice.actions;

export default ThemeSlice.reducer;
