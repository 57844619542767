/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCompletedScrappersApi,
  getComponentsApi,
  saveFlowsApi
} from "../../Services/flowServices";
import {
  deleteCustomComponentApi,
  getCustomComponentTemplateApi
} from "../../Services/componentServices";
import { transformComponentData } from "../../Utils/helpers";

export const fetchComponents = createAsyncThunk(
  "flows/fetchComponents",
  async () => {
    const response = await getComponentsApi();
    return transformComponentData(response.data);
  }
);

export const fetchCustomComponentTemplate = createAsyncThunk(
  "flows/fetchCustomComponentTemplate",
  async () => {
    const { data } = await getCustomComponentTemplateApi();
    return data.template; // Return the template directly
  }
);

export const fetchCompletedScrappers = createAsyncThunk(
  "flows/fetchCompletedScrappers",
  async () => {
    const { data } = await getCompletedScrappersApi();
    return data; // Return the completed scrapper list directly
  }
);

export const deleteComponent = createAsyncThunk(
  "flows/deleteComponent",
  async (componentId) => {
    await deleteCustomComponentApi(componentId);
    return componentId; // Return componentId to access in the reducer
  }
);

export const saveFlow = createAsyncThunk(
  "flows/saveFlow",
  async ({ payload, flowId }, { rejectWithValue }) => {
    try {
      const response = await saveFlowsApi(payload, flowId);
      return response.data; // Return the API response if successful
    } catch (error) {
      console.log("🚀 ~ error:", error);
      return rejectWithValue(error.response?.data || "Failed to save flow"); // Return error in failure
    }
  }
);

const initialState = {
  flowNodes: {
    past: [],
    present: [],
    future: []
  },
  flowEdges: {
    past: [],
    present: [],
    future: []
  },
  flowList: [],
  selectedFlow: null,
  activeNode: null,
  components: {}, // Store for components
  customComponentTemplate: "", // Store for the custom component template
  initialFlowElements: {
    isUpdated: false, // Track whether both arrays have been synced
    nodesInitialized: false, // Track if nodes have been set at least once
    edgesInitialized: false, // Track if edges have been set at least once
    nodes: [], // To store initial nodes
    edges: [] // To store initial edges
  },
  tweeks: {},
  tweeksParamNodes: [],
  completedScrapperList: [],
  loading: false, // Track loading state for async actions
  isSavedFlow: false,
  error: null,
  lastUndoAction: [], // Separate state for tracking last undo action
  lastRedoAction: [] // Separate state for tracking last redo action
};

const FlowsSlice = createSlice({
  name: "flows",
  initialState,
  reducers: {
    setActiveNode: (state, action) => {
      state.activeNode = action.payload;
    },
    setFlowNodes: (state, action) => {
      const past = state.flowNodes?.past || [];
      const present = state.flowNodes?.present || [];
      const incomingNodes = action.payload.nodes;
      const isEdit = action.payload.isEdit;
      // Detect added nodes: nodes in incomingNodes but not in present
      const addedNodes = incomingNodes.filter(
        (incomingNode) => !present.some((node) => node.id === incomingNode.id)
      );

      // Detect removed nodes: nodes in present but not in incomingNodes
      const removedNodes = present.filter(
        (existingNode) =>
          !incomingNodes.some((node) => node.id === existingNode.id)
      );

      // Update state based on detected changes
      if (addedNodes.length > 0) {
        // Optional undo action
        if (!(isEdit && present.length === 0)) {
          state.lastUndoAction.push({
            type: "add",
            entity: "node",
            items: addedNodes
          });
        }
        state.flowNodes = {
          past: [...past, present],
          present: [...present, ...addedNodes],
          future: []
        };
      } else if (removedNodes.length > 0) {
        // Optional undo action
        if (!(isEdit && present.length === 0)) {
          state.lastUndoAction.push({
            type: "remove",
            entity: "node",
            items: removedNodes
          });
        }
        state.flowNodes = {
          past: [...past, present],
          present: present.filter(
            (node) =>
              !removedNodes.some((removedNode) => removedNode.id === node.id)
          ),
          future: []
        };
      } else {
        // Checking and updating only specific properties
        const updatedNodes = incomingNodes.map((incomingNode) => {
          const existingNode = present.find(
            (node) => node.id === incomingNode.id
          );
          // Assuming `data` is the property that might change
          if (existingNode && existingNode.data !== incomingNode.data) {
            return { ...existingNode, ...incomingNode }; // Merge updates into existing nodes
          }
          return existingNode;
        });

        // Check if updates need to be made (shallow check)
        const needsUpdate = updatedNodes.some(
          (node, index) => node.data !== present[index].data
        );

        if (needsUpdate) {
          state.flowNodes = {
            past: [...past, present],
            present: updatedNodes,
            future: []
          };
        }
      }

      // If no nodes were added or removed, do not modify state
    },
    setFlowEdges: (state, action) => {
      const past = state.flowEdges?.past || [];
      const present = state.flowEdges?.present || [];
      const incomingEdges = action.payload.edges;
      const isEdit = action.payload.isEdit;

      const addedEdges = incomingEdges.filter(
        (incomingEdge) => !present.some((edge) => edge.id === incomingEdge.id)
      );

      // Determine removed edges (in present but not in incomingEdges)
      const removedEdges = present.filter(
        (edge) =>
          !incomingEdges.some((incomingEdge) => incomingEdge.id === edge.id)
      );

      if (addedEdges.length > 0) {
        if (!(isEdit && present.length === 0)) {
          state.lastUndoAction.push({
            type: "add",
            entity: "edge",
            items: addedEdges
          });
        }
        state.flowEdges = {
          past: [...past, present],
          present: [...present, ...addedEdges],
          future: []
        };
      }
      if (removedEdges.length > 0) {
        if (!(isEdit && present.length === 0)) {
          state.lastUndoAction.push({
            type: "remove",
            entity: "edge",
            items: removedEdges
          });
        }
        state.flowEdges = {
          past: [...past, present],
          present: present.filter(
            (edge) =>
              !removedEdges.some((removedEdge) => removedEdge.id === edge.id)
          ),
          future: []
        };
      }
    },
    resetUndo: (state) => {
      state.lastUndoAction = [];
    },
    undoFlowNodesAndEdges: (state) => {
      if (state.lastUndoAction.length === 0) return;

      const lastAction = state.lastUndoAction.pop();
      state.lastRedoAction.push(lastAction);

      if (lastAction.entity === "node") {
        if (lastAction.type === "add") {
          state.flowNodes.present = state.flowNodes.present.filter(
            (node) =>
              !lastAction.items.some((addedNode) => addedNode.id === node.id)
          );
        } else if (lastAction.type === "remove") {
          state.flowNodes.present = [
            ...state.flowNodes.present,
            ...lastAction.items
          ];
        }
      } else if (lastAction.entity === "edge") {
        if (lastAction.type === "add") {
          state.flowEdges.present = state.flowEdges.present.filter(
            (edge) =>
              !lastAction.items.some((addedEdge) => addedEdge.id === edge.id)
          );
        } else if (lastAction.type === "remove") {
          state.flowEdges.present = [
            ...state.flowEdges.present,
            ...lastAction.items
          ];
        }
      }
    },
    redoFlowNodesAndEdges: (state) => {
      if (state.lastRedoAction.length === 0) return;

      const lastRedo = state.lastRedoAction.pop();
      state.lastUndoAction.push(lastRedo);

      if (lastRedo.entity === "node") {
        if (lastRedo.type === "add") {
          // Redo by adding the nodes back
          state.flowNodes.present = [
            ...state.flowNodes.present,
            ...lastRedo.items
          ];
        } else if (lastRedo.type === "remove") {
          // Redo by removing the nodes
          state.flowNodes.present = state.flowNodes.present.filter(
            (node) =>
              !lastRedo.items.some((removedNode) => removedNode.id === node.id)
          );
        }
      } else if (lastRedo.entity === "edge") {
        if (lastRedo.type === "add") {
          // Redo by adding the edges back
          state.flowEdges.present = [
            ...state.flowEdges.present,
            ...lastRedo.items
          ];
        } else if (lastRedo.type === "remove") {
          // Redo by removing the edges
          state.flowEdges.present = state.flowEdges.present.filter(
            (edge) =>
              !lastRedo.items.some((removedEdge) => removedEdge.id === edge.id)
          );
        }
      }
    },
    clearFlowState: (state) => {
      state.activeNode = null;
      state.flowNodes = [];
      state.flowEdges = [];
      state.flowList = [];
      state.initialFlowElements = {
        isUpdated: false,
        nodesInitialized: false,
        edgesInitialized: false,
        nodes: [],
        edges: []
      };
      state.lastUndoAction = [];
      state.lastRedoAction = [];
      state.error = null;
    },

    updateNodeFieldValue: (state, action) => {
      const { nodeId, key, value } = action.payload;

      const targetNode = state.flowNodes.present?.find(
        (node) => node.id === nodeId
      );
      if (targetNode) {
        if (!targetNode.fieldValues) {
          targetNode.fieldValues = {};
        }
        targetNode.fieldValues[key] = value;
      }
    },

    setFieldValuesFromParams: (state, action) => {
      const { params } = action.payload;
      params.forEach(({ nodeId, key, value }) => {
        const node = state.flowNodes.present.find((node) => node.id === nodeId);
        if (node) {
          if (!node.fieldValues) {
            node.fieldValues = {};
          }
          node.fieldValues[key] = value;
        }
      });
    },

    setFlowsError: (state, action) => {
      state.error = action.payload;
    },

    setFlowsList: (state, action) => {
      state.flowList = action.payload;
    },

    deleteFlowFromState: (state, action) => {
      state.flowList = state.flowList.filter(
        (flow) => flow.flow_id !== action.payload
      );
    },

    duplicateFlowInState: (state, action) => {
      state.flowList.push(action.payload);
    },

    updateFlowInState: (state, action) => {
      const { flowId, updatedFlow } = action.payload;
      const index = state.flowList.findIndex((flow) => flow.flow_id === flowId);
      if (index !== -1) {
        state.flowList[index] = { ...state.flowList[index], ...updatedFlow };
      }
    },

    updateSelectedFlow: (state, action) => {
      const presentFlowNodes = state.flowNodes.present;
      state.tweeksParamNodes =
        presentFlowNodes &&
        presentFlowNodes.filter(
          (node) => node.type === "custom" || node.type === "dynamic"
        );
      state.selectedFlow = action.payload;
      state.tweeks =
        presentFlowNodes &&
        presentFlowNodes
          .filter((x) => x.type === "custom" || x.type === "dynamic")
          .reduce((obj, y) => {
            obj[y.id] = {};
            return obj;
          }, {});
    },

    updateNodeParams: (state, action) => {
      const { key, value, id } = action.payload;

      const nodeIndex = state.tweeksParamNodes.findIndex((x) => x.id === id);

      if (nodeIndex !== -1) {
        const node = state.tweeksParamNodes[nodeIndex];
        const { params } = node.data;

        if (params && params[key]) {
          params[key].value = value;

          if (!state.tweeks[id]) {
            state.tweeks[id] = {};
          }
          state.tweeks[id][key] = value;
        }
      }
    },

    addCustomComponent: (state, action) => {
      const newComponent = action.payload;

      if (!state.components) {
        state.components = {}; // Initialize components if it doesn't exist
      }

      if (!state.components.Saved) {
        state.components.Saved = { components: [] }; // Initialize Saved if it doesn't exist
      }

      state.components.Saved.components.push({
        ...newComponent,
        type: "dynamic"
      });
    },

    updateCustomComponent: (state, action) => {
      const { componentId, updatedComponent } = action.payload;
      const savedCategory = state.components.Saved;

      if (savedCategory) {
        const index = savedCategory.components.findIndex(
          (component) => component.custom_component_id === componentId
        );
        if (index !== -1) {
          savedCategory.components[index] = {
            ...updatedComponent,
            type: "dynamic"
          };
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchComponents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchComponents.fulfilled, (state, action) => {
        state.loading = false;
        state.components = action.payload;
      })
      .addCase(fetchComponents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCompletedScrappers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompletedScrappers.fulfilled, (state, action) => {
        state.loading = false;
        state.completedScrapperList = action.payload;
      })
      .addCase(fetchCompletedScrappers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCustomComponentTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomComponentTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.customComponentTemplate = action.payload; // Set template
      })
      .addCase(fetchCustomComponentTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Set error
      })
      .addCase(deleteComponent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteComponent.fulfilled, (state, action) => {
        state.loading = false;
        const componentId = action.payload;
        const savedCategory = state.components.Saved;
        if (savedCategory) {
          savedCategory.components = savedCategory.components.filter(
            (component) => component.custom_component_id !== componentId
          );
        }
      })
      .addCase(deleteComponent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(saveFlow.pending, (state) => {
        state.isSavedFlow = true;
      })
      .addCase(saveFlow.fulfilled, (state) => {
        state.isSavedFlow = false;
      })
      .addCase(saveFlow.rejected, (state, action) => {
        state.isSavedFlow = false;
        state.error = action.payload;
      });
  }
});

export const {
  setActiveNode,
  setFlowNodes,
  setFlowEdges,
  resetUndo,
  clearFlowState,
  redoFlowNodesAndEdges,
  undoFlowNodesAndEdges,
  updateNodeFieldValue,
  setFieldValuesFromParams,
  setFlowsError,
  setFlowsList,
  deleteFlowFromState,
  duplicateFlowInState,
  updateFlowInState,
  updateSelectedFlow,
  redoSelectedFlow,
  undoSelectedFlow,
  updateNodeParams,
  addCustomComponent,
  updateCustomComponent
} = FlowsSlice.actions;

export default FlowsSlice.reducer;
