import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import UserSlice, { logout } from "./slices/UserSlice";
import workflowSlice from "./slices/workflowSlice"; // Renamed for clarity
import ThemeSlice from "./slices/ThemeSlice";
import ScraperSlice from "./slices/scrapperSlice";

const persistConfig = {
  key: "appRoot", // Updated key name for clarification
  storage
};

const appReducer = combineReducers({
  user: UserSlice,
  workflow: workflowSlice, // Updated to reflect the slice's purpose
  theme: ThemeSlice,
  scraper: ScraperSlice
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    // Clear the persisted storage when logging out
    storage.removeItem("persist:root");
    const newState = undefined; // Create a new variable to hold the reset state
    return appReducer(newState, action); // Pass the new variable to the appReducer
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

const persistor = persistStore(store);

export { store, persistor };
