import axios from "axios";
import { flowAxiosInstance } from "./axiosInterceptors"; // Import the axios instance with interceptors

export const getFlowsApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/flows/all"); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const removeFlowsApi = async (flowId) => {
  try {
    const response = await flowAxiosInstance.delete(`/flows/?flowId=${flowId}`); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};
export const duplicateFlowsApi = async (flowId, newFlowId) => {
  try {
    const response = await flowAxiosInstance.post(
      `flows/duplicate?flowId=${flowId}&newFlowId=${newFlowId}`
    ); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const saveFlowsApi = async (formData, flowId) => {
  const token = localStorage.getItem("user-token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FLOW_URL}/flows/save?flowId=${flowId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Axios automatically sets the correct boundaries
          Authorization: `Bearer ${token}` // Include the token in Authorization header
        }
      }
    );
    return response;
  } catch (error) {
    console.error("Error during save:", error);
    throw error;
  }
};

export const updateFlowsApi = async (flowId, flowName, flowData) => {
  try {
    const response = await flowAxiosInstance.put(`flows/?flowId=${flowId}`, {
      flowName,
      updateFlowData: flowData
    }); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const getSingleFlowDataApi = async (flowId) => {
  try {
    const response = await flowAxiosInstance.get(`flows/?flowId=${flowId}`); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const getComponentsApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/flows/components"); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const getVersionApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/flows/version");
    return response;
  } catch (error) {
    console.error("Error getting version:", error);
    throw error;
  }
};

export const buildFlowApi = async (payload) => {
  try {
    const response = await flowAxiosInstance.post(
      `/flows/compile-graph/?flowId=${payload.flowId}`,
      {
        graph: payload.graph,
        required_outputs: payload.required_outputs
      }
    ); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const getCompletedScrappersApi = async () => {
  try {
    const response = await flowAxiosInstance.get("scraper/completed");
    return response;
  } catch (error) {
    console.error("Error getting completed scrappers list:", error);
    throw error;
  }
};
